import React from 'react';
import './page-footer.scss';

const PageFooter = () => {
  return (
    <footer className="PageFooter">
      <nav>
        <div className="left">
          <a className="contact" href="/contact">Contact</a>
        </div>
        <div className="right">
          <a className="twitter" href="https://twitter.com/1stness_journal" target="_blank" rel="noreferrer">Twitter</a>
          <a className="rss" href="https://firstness.org/rss.xml" target="_blank" rel="noreferrer">RSS</a>
        </div>
      </nav>
    </footer>
  );
};

export default PageFooter;
