import React from 'react';
import clsx from 'clsx';
import './page-layout.scss';

const PageLayout = ({ className, children }) => {
  return (
    <div className={clsx("PageLayout", className)}>
      {children}
    </div>
  );
};

export default PageLayout;
