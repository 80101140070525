import React from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/page-header';
import PageFooter from '../../components/page-footer';
import PageLayout from '../../components/page-layout';
import './index.scss';

const ContactPage = () => {
  return (
    <PageLayout className="ContactPage">
      <Helmet>
        <title>{`Firstness Journal · Contact`}</title>
      </Helmet>
      <PageHeader />
      <main>
        <h1>Contact</h1>
        <form name="contact" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <label>
              <span>Name<br /></span>
              <input type="text" name="name" />
            </label>
          </p>
          <p>
            <label>
              <span>Email<br /></span>
              <input type="email" name="email" />
            </label>
          </p>
          <p>
            <label>
              <span>Message<br /></span>
              <textarea name="message" rows={8}></textarea>
            </label>
          </p>
          <p>
            <button type="submit">Send</button>
          </p>
        </form>
      </main>
      <PageFooter />
    </PageLayout>
  );
};

export default ContactPage;
