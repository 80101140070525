import React from 'react';
import logo from '../images/logo.svg';
import './page-header.scss';

const PageHeader = () => {
  return (
    <header className="PageHeader">
      <a className="logo" href="https://firstness.org">
        <img src={logo} alt="Firstness Journal Logo" />
      </a>
      <nav>
        <div className="left">
          <a className="read" href="/#read">Read</a>
          <a className="subscribe" href="https://firstness.org/rss.xml" target="_blank" rel="noreferrer">Subscribe</a>
          <a className="buy" href="https://www.imperiumpress.org/shop" target="_blank" rel="noreferrer">Buy</a>
        </div>
        <div className="right">
          <a className="about" href="/about">About</a>
        </div>
      </nav>
    </header>
  );
};

export default PageHeader;
